<template>
  <div class="index">
    <div class="container">
      <!-- 主容器，分左右，右边长列表 -->
      <div class="i-con">
        <!-- 左侧长内容 -->
        <div class="i-l">
          <!-- 头部 -->
          <div class="s-header">
            <h2>{{ this.title }}</h2>
            <span> <i class="iconfont icon-zuozhe"></i> {{ this.author }}</span>
            <span> <i class="iconfont icon-time"></i> 发布: {{ this.time }} </span>
            <span> <i class="iconfont icon-moban"></i> {{ this.sub }}阅读</span>
          </div>
          <!-- 简介 -->
          <div class="s-jj">
            <p>{{ this.article1 }}</p>
          </div>
          <!-- 内容 -->
          <div class="s-con">
            <!-- 图片 -->
            <img :src="this.img" alt="" />
            <!-- 文字 -->
            <div class="s-text">
              <p>{{ this.article1 }}</p>
              <p>{{ this.article2 }}</p>
              <p>{{ this.article3 }}</p>
            </div>
          </div>
        </div>

        <!-- 右侧长列表 -->
        <div class="i-r">
          <!-- banner 区块 -->
          <div class="banner">
            <img src="/imgs/b6.jpg" alt="" />
            <p>数字货币等同于电子支付吗？</p>
          </div>
          <!-- 资讯区块2 -->
          <div class="r2">
            <!-- 标题 -->
            <div class="title">热门资讯</div>

            <!-- 循环项 -->
            <div
              class="r2-item"
              v-for="(item, index) in this.a3"
              :key="index"
              @click="goDays(item.methods, item.id)"
            >
              <!-- 左侧图片 -->
              <img :src="item.img" alt="" />
              <!-- 右侧标题 -->
              <h2>{{ item.title }}</h2>
            </div>
          </div>

          <!-- banner2 -->

          <!-- 资讯区块3 -->
          <div class="r3">
            <!-- 标题 -->
            <div class="title">其他推荐</div>

            <!-- 循环项 -->
            <div
              class="r2-item"
              v-for="(item, index) in this.a4"
              :key="index"
              @click="goDays(item.methods, item.id)"
            >
              <!-- 左侧图片 -->
              <img :src="item.img" alt="" />
              <!-- 右侧标题 -->
              <h2>{{ item.title }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      href: "index.html",
      // 获取 id
      myId: this.$route.query.type,
      // 获取 标题
      title: "",
      // 简介
      concise: "",
      // 作者
      author: "",
      // 图片路径
      img: "",

      // 文章1
      article1: "",
      // 文章2
      article2: "",
      article3: "",
      time: "",
      ac3: [],
      sub: "",
      a1: [],
      a2: [],
      a3: [],
      a4: [],
    };
  },
  components: {},
  mounted() {
    this.init();
    this.init2();
    document.title = "华谊兄弟（北京）影业有限公司";
  },
  methods: {
    init() {
      this.axios.get("/mock/ac1.json").then((res) => {
        var vl = res.data;
        var date = vl.find((vl) => vl.id == this.myId);
        console.log(date);
        // console.log(date)
        // 标题
        this.title = date.title;
        // 作者
        this.author = date.author;
        // 歌词
        this.concise = date.concise;
        // 图片路径
        this.img = date.img;
        // 文章 1
        this.article1 = date.article1;
        // 文章 2
        this.article2 = date.article2;
        this.article3 = date.article3;
        this.time = date.time;
        this.sub = date.sub;
        this.ac3 = vl.slice(0, 7);
      });
    },
    init2() {
      this.axios.get("/mock/ac1.json").then((res) => {
        const data = res.data;
        this.data = data;
        this.a1 = data.slice(0, 4);
        this.a2 = data.slice(4, 10);
        this.a3 = data.slice(8, 12);
        this.a4 = data.slice(12, 16);
      });
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
      location.reload();
    },
    // 收费逻辑
    gopay() {
      // console.log('1')

      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("资讯每条3元，点击确定注册购买");
        this.$router.push({ path: "/register", query: { k: 1 } });
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.index {
  background-color: #eee;
  // margin-top: 80px;
  .container {
    padding-top: 10px;
    box-sizing: border-box;
    // 主容器1
    .i-con {
      display: flex;
      justify-content: space-between;
      width: 100%;
      // height: 3000px;
      // background-color: pink;
      margin: 20px auto 0;
      // 左侧
      .i-l {
        padding: 20px;
        width: 72%;
        height: 100%;
        background-color: #fff;
        box-sizing: border-box;

        // 头部
        .s-header {
          width: 100%;
          height: 100px;
          background-color: #fff;
          text-align: center;
          h2 {
            margin-bottom: 30px;
          }
          span {
            font-size: 14px;
            margin: 0 15px;
          }
        }

        // 简介
        .s-jj {
          width: 600px;
          // height: 200px;
          margin: 0 auto;
          p {
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }

        // 文章容器
        .s-con {
          // 图片
          img {
            width: 100%;
            height: 400px;
            margin: 30px auto;
            background-color: pink;
          }
          // 文章
          .s-text {
            width: 100%;
            text-align: left;
            padding: 20px;
            box-sizing: border-box;
            p {
              text-indent: 30px;
              margin: 30px auto;
              font-size: 14px;
              line-height: 70px;
            }
          }
        }
      }

      // 右侧
      .i-r {
        width: 28%;
        height: 100%;
        background-color: #fff;
        margin-left: 10px;
        padding: 20px;
        box-sizing: border-box;

        // banner 区块1
        .banner {
          width: 100%;
          height: 200px;
          position: relative;
          margin-bottom: 30px;
          img {
            width: 100%;
            height: 100%;
          }
          p {
            width: 100%;
            height: 30px;
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
            bottom: 0;
            left: 0;
            font-size: 12px;
            line-height: 30px;
            // padding-left: 10px;
            box-sizing: border-box;
            color: #fff;
          }
        }

        // 资讯区块2
        .r2 {
          width: 100%;

          // height: 600px;

          // 标题
          .title {
            width: 100%;
            // height: 40px;
            border-bottom: 1px solid #ccc;
            font-size: 24px;
            line-height: 40px;
            padding: 10px 0;
            box-sizing: border-box;
          }

          // 循环项
          .r2-item {
            width: 100%;
            height: 100px;
            background-color: #fff;
            display: flex;
            align-items: center;
            margin: 20px 0;
            &:hover {
              h2 {
                color: $colorZ;
              }
            }
            img {
              width: 150px;
              height: 100px;
              margin-right: 5px;
            }
            h2 {
              font-size: 12px;
              color: #333;
              width: 175px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              cursor: pointer;
              transition: all 0.2s;
            }
          }
        }

        // banner2
        .banner2 {
          width: 100%;
          height: 300px;
          // background: #000;
          margin: 30px 0;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .r3 {
          width: 100%;
          // height: 600px;

          // 标题
          .title {
            width: 100%;
            // height: 40px;
            border-bottom: 1px solid #ccc;
            font-size: 24px;
            line-height: 40px;
            padding: 5px 0;
            box-sizing: border-box;
          }

          // 循环项
          .r2-item {
            width: 100%;
            height: 100px;
            background-color: #fff;
            display: flex;
            align-items: center;
            margin: 20px 0;
            &:hover {
              h2 {
                color: $colorZ;
              }
            }
            img {
              width: 150px;
              height: 100px;
              margin-right: 5px;
            }
            h2 {
              font-size: 12px;
              color: #333;
              width: 175px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              cursor: pointer;
              transition: all 0.2s;
            }
          }
        }
      }
    }
  }
}
</style>
