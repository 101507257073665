<template>
  <div class="index">
    <div class="container">
      <!-- 左侧 -->
      <div class="l">
        <!-- banner -->
        <div class="banner">
          <img src="/imgs/b1.jpg" alt="" />
        </div>

        <!-- 标题资讯 st -->
        <div class="l-title">
          <!-- 上层标题 -->
          <div class="title-s">
            <span>[今日头条]</span>
            <span>谷歌量子计算机运算200秒=世界第一超算运算10000年</span>
          </div>

          <!-- 简介 -->
          <div class="jj">
            这或许是计算机领域的一个里程碑事件：谷歌宣称“量子霸权”已经实现，他们首次在实验中证明了量子计算机对于传统架构计算机的优越性：在世界第一超算 Summit 需要计算 1 万年的实验中，谷歌的量子计算机只用了 3 分 20 秒。
          </div>
        </div>
        <!-- 标题资讯 end -->

        <!-- 资讯区块1 st -->
        <div class="a1">
          <!-- 循环项 -->
          <div
            class="a1-item"
            v-for="(item, index) in this.a1"
            :key="index"
            @click="gopay(item.methods, item.id)"
          >
            <!-- 左侧 图片 -->
            <div class="item-img">
              <img :src="item.img" alt="" />
            </div>
            <!-- 右侧信息 -->
            <div class="item-info">
              <h2>{{ item.title }}</h2>
              <p>
                {{ item.jj }}
              </p>
            </div>
          </div>
        </div>
        <!-- 资讯区块1 end -->

        <!-- banner2 -->
        <div class="banner2">
          <img src="/imgs/b2.jpg" alt="" />
        </div>

        <!-- 资讯区块2 -->
        <div class="a2">
          <!-- 标题 -->
          <div class="a2-title">
            <h2>最新发布</h2>
          </div>
          <!-- 循环项 -->
          <div
            class="a2-item"
            v-for="(item, index) in this.a2"
            :key="index"
            @click="gopay(item.methods, item.id)"
          >
            <!-- 左侧图片 -->
            <div class="item-img">
              <img :src="item.img" alt="" />
            </div>
            <!-- 右侧信息 -->
            <div class="item-info">
              <!-- 标题 -->
              <div class="item-title">
                <span>收费资讯 <i></i></span>
                <h2>{{ item.title }}</h2>
              </div>

              <!-- 信息层 -->
              <div class="item-time">
                <!-- 时间 -->
                <span class="time">
                  <i class="iconfont icon-time"></i>
                  {{ item.time }}</span
                >

                <!-- 阅读 -->
                <span class="yd">
                  <i class="iconfont icon-zixun1"></i>
                  阅读({{ item.sub }})</span
                >

                <!-- 作者 -->
                <span class="name">
                  <i class="iconfont icon-zuozhe"></i>
                  {{ item.author }}</span
                >
              </div>

              <!-- 简介 -->
              <p>{{ item.article1 }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 右侧 -->
      <div class="r">
        <!-- banner 区块 -->
        <div class="banner">
          <img src="/imgs/b6.jpg" alt="" />
          <!-- <p>数字货币等同于电子支付吗？</p> -->
        </div>

        <!-- 资讯区块2 -->
        <div class="r2">
          <!-- 标题 -->
          <div class="title">热门资讯</div>

          <!-- 循环项 -->
          <div
            class="r2-item"
            v-for="(item, index) in this.a3"
            :key="index"
            @click="gopay(item.methods, item.id)"
          >
            <!-- 左侧图片 -->
            <img :src="item.img" alt="" />
            <!-- 右侧标题 -->
            <h2>{{ item.title }}</h2>
          </div>
        </div>

        <!-- banner2 -->
        <div class="banner2">
          <img src="/imgs/b3.jpg" alt="" />
        </div>

        <!-- 资讯区块3 -->
        <div class="r3">
          <!-- 标题 -->
          <div class="title">其他推荐</div>

          <!-- 循环项 -->
          <div
            class="r2-item"
            v-for="(item, index) in this.a4"
            :key="index"
            @click="gopay(item.methods, item.id)"
          >
            <!-- 左侧图片 -->
            <img :src="item.img" alt="" />
            <!-- 右侧标题 -->
            <h2>{{ item.title }}</h2>
          </div>
        </div>
      </div>
    </div>
      <modal
      title="请选择支付方式"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img
          style="display: flex; justify-content: center; margin: 0 auto"
          src="/imgs/pay2.png"
        />
        <h2>微信支付：2元/篇</h2>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "./../components/Modal";
export default {
  data() {
    return {
      data: [],
      a1: [],
      a2: [],
      a3: [],
      a4: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  components: {
    Modal,
  },
  mounted() {
    this.init();
    document.title = "江苏淮田万顷农业科技有限公司";
  },
  methods: {
    init() {
      this.axios.get("/mock/ac1.json").then((res) => {
        const data = res.data;
        this.data = data;
        this.a1 = data.slice(0, 4);
        this.a2 = data.slice(4, 10);
        this.a3 = data.slice(8, 12);
        this.a4 = data.slice(12, 17);
      });
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 详情
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
     // 收费逻辑
    gopay() {
      // alert("付费阅读，2元/篇");
      this.showModal = true;
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("付费阅读，2元/篇");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.index {
  background-color: #f5f5f5;
  position: relative;
  padding-top: 20px;
  box-sizing: border-box;
  .container {
    display: flex;
    justify-content: space-between;
    // 左侧
    .l {
      width: 70%;
      height: 100%;
      background-color: #fff;
      //   banner区块
      .banner {
        width: 100%;
        height: 200px;
        // background-color: #c60023;
        img {
          width: 100%;
          height: 100%;
        }
      }

      //  标题资讯
      .l-title {
        width: 100%;
        height: 70px;
        margin: 20px 0;
        // background-color: red;
        border-bottom: 2px solid #eee;
        // 上层标题
        .title-s {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          span {
            font-size: 18px;
            font-weight: bold;
            &:nth-child(1) {
              font-size: 18px;
              margin-right: 15px;
              color: $colorZ;
            }
          }
        }

        // 简介
        .jj {
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          padding: 0 15px 0 0;
          box-sizing: border-box;
        }
      }

      // 资讯区块1
      .a1 {
        width: 100%;
        // height: 300px;
        // background: red;
        margin: 30px 0;
        @include flex();
        flex-wrap: wrap;
        // 循环项
        .a1-item {
          width: 419px;
          height: 150px;
          background-color: #eee;
          margin: 5px;
          &:hover {
            h2 {
              color: $colorZ;
            }
          }
          @include flex();
          // 图片
          .item-img {
            width: 400px;
            height: 100%;
            margin-right: 5px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              background-color: aqua;
              transition: all 0.2s;
              &:hover {
                transform: scale(1.1);
                // overflow: hidden;
              }
            }
          }

          //   信息
          .item-info {
            padding: 7px;
            box-sizing: border-box;
            flex: 1;
            h2 {
              font-size: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 200px;
              cursor: pointer;
              transition: all 0.2s;
            }
            p {
              font-size: 12px;
              margin: 20px 0;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              line-height: 31px;
            }
          }
        }
      }

      //   banner2
      .banner2 {
        width: 100%;
        height: 200px;
        // background-color: #c60023;
        img {
          width: 100%;
          height: 100%;
        }
      }

      // 资讯区块 2
      .a2 {
        //  标题
        .a2-title {
          width: 100%;
          height: 40px;
          @include flex();
          padding: 30px 0;
          box-sizing: border-box;
          border-bottom: 2px solid #ccc;
          h2 {
            font-size: 24px;
            font-weight: 400;
          }
        }

        // 循环项
        .a2-item {
          width: 100%;
          height: 200px;
          background: #fff;
          display: flex;
          align-items: center;
          transition: all 0.2s;
          margin: 20px auto;
          padding: 10px;
          box-sizing: border-box;
          &:hover {
            box-shadow: 0px 0px 5px #888;
            .item-title {
              h2 {
                color: $colorZ;
              }
            }
          }
          // 左侧图片
          .item-img {
            width: 250px;
            height: 100%;
            margin-right: 15px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          // 右侧信息层
          .item-info {
            width: 500px;
            // 标题
            .item-title {
              width: 100%;
              height: 30px;
              display: flex;
              align-items: center;
              transition: all 0.2s;
              span {
                display: inline-block;
                width: 80px;
                height: 100%;
                background-color: $colorZ;
                margin-right: 10px;
                line-height: 30px;
                text-align: center;
                font-size: 12px;
                color: #fff;
                // padding: 10px;
                box-sizing: border-box;
                position: relative;
                i {
                  position: absolute;
                  top: 50%;
                  margin-top: -4px;
                  right: -4px;
                  display: inline-block;
                  width: 0;
                  height: 0;
                  vertical-align: middle;
                  border-left: 4px solid $colorZ;
                  border-top: 4px solid transparent;
                  border-bottom: 4px solid transparent;
                }
              }
              h2 {
                transition: all 0.2s;
                width: 400px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            // 信息层
            .item-time {
              width: 100%;
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #888;
              margin: 30px 0;
              span {
                margin-right: 15px;
              }
            }

            // 简介
            p {
              font-size: 12px;
              color: #888;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              width: 100%;
              // height: 100;
              // background: #000;
            }
          }
        }
      }
    }
    // 右侧
    .r {
      width: 30%;
      height: 100%;
      background-color: #fff;
      margin-left: 30px;

      // banner 区块1
      .banner {
        width: 100%;
        height: 200px;
        position: relative;
        margin-bottom: 30px;
        img {
          width: 100%;
          height: 100%;
        }
        p {
          width: 100%;
          height: 30px;
          background-color: rgba(0, 0, 0, 0.5);
          position: absolute;
          bottom: 0;
          left: 0;
          font-size: 12px;
          line-height: 30px;
          // padding-left: 10px;
          box-sizing: border-box;
          color: #fff;
          // text-align: center;
          font-weight: bold;
        }
      }

      // 资讯区块2
      .r2 {
        width: 100%;
        // height: 600px;

        // 标题
        .title {
          width: 100%;
          // height: 40px;
          border-bottom: 1px solid #ccc;
          font-size: 24px;
          line-height: 40px;
          padding: 5px 0;
          box-sizing: border-box;
        }

        // 循环项
        .r2-item {
          width: 100%;
          height: 100px;
          background-color: #fff;
          display: flex;
          align-items: center;
          margin: 20px 0;
          &:hover {
            h2 {
              color: $colorZ;
            }
          }
          img {
            width: 150px;
            height: 100px;
            margin-right: 5px;
          }
          h2 {
            font-size: 12px;
            color: #333;
            width: 175px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            transition: all 0.2s;
          }
        }
      }

      // banner2
      .banner2 {
        width: 100%;
        height: 300px;
        // background: #000;
        margin: 30px 0;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .r3 {
        width: 100%;
        height: 600px;

        // 标题
        .title {
          width: 100%;
          // height: 40px;
          border-bottom: 1px solid #ccc;
          font-size: 24px;
          line-height: 40px;
          padding: 5px 0;
          box-sizing: border-box;
        }

        // 循环项
        .r2-item {
          width: 100%;
          height: 100px;
          background-color: #fff;
          display: flex;
          align-items: center;
          margin: 20px 0;
          &:hover {
            h2 {
              color: $colorZ;
            }
          }
          img {
            width: 150px;
            height: 100px;
            margin-right: 5px;
          }
          h2 {
            font-size: 12px;
            color: #333;
            width: 175px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            transition: all 0.2s;
          }
        }
      }
    }
  }
}
</style>
